/*********************/
/* GLOBAL JAVASCRIPT */
/*********************/
/*
	This file contains some global JavaScript code that is included on every page of the website.
	You can use this to do common tasks before rendering the page or add a missing feature, like structured data.
*/


// Get logo URL from the first favicon it finds:
const logo = (
	document.querySelector('link[rel="icon"]') ||
	document.querySelector('link[rel="apple-touch-icon"]') ||
	document.querySelector('link[rel="apple-touch-icon-precomposed"]') ||
	document.querySelector('link[rel="shortcut icon"]')
).href;

const companyName = "everyone codes";

// Get sameAs URLs from the footer links:
let sameAs;
for (let link of document.querySelectorAll('footer a[target="_blank"][href^="http"]')) {
	if (!sameAs) sameAs = [];
	sameAs.push(link.href);
}
// create schema.org organization object
const organization = {
	'@context': 'https://schema.org',
	'@type': 'Organization',
	'@id': location.origin + '/#/schema/organization',
	name: companyName,
	url: location.origin,
	logo,
	sameAs,
};

// Add structured data (schema markup) to the head of the page, generated from content:
let structuredDataEl = document.createElement('script');
structuredDataEl.type = 'application/ld+json';

// Add course-specific structured data:
if(document.querySelector('[course]') || document.querySelector('[course-name]')) {
	let courses = document.querySelectorAll('[course]');
	let courseItems = [];
	const addCourse = (course, index) => {
		let courseName = course.querySelector('[course-name]').textContent;
		let courseDescription = course.querySelector('[course-desc]').textContent;
		let courseUrl = course.getElementsByTagName('a')[0].href;

		courseItems.push({
			"@type": "ListItem",
			position: index + 1,
			item:
			{
				"@type": "Course",
				"@id": courseUrl,
				name: courseName,
				url: courseUrl,
				description: courseDescription,
				provider: organization
			}
		});
	}
	if(courses.length > 1) {
		courses.forEach(addCourse);
	} else {
		addCourse(document, 0);
	}
	structuredDataEl.textContent = JSON.stringify({
		'@context': 'https://schema.org',
		'@type': 'ItemList',
		'@id': location.href,
		name: 'Our Courses',
		itemListElement: courseItems
	});
} else if(document.querySelector('[course-name]')) {
	let courseName = document.querySelector('[course-name]').textContent;
	let courseDescription = document.querySelector('[course-desc]').textContent;

	structuredDataEl.textContent = JSON.stringify({
		'@context': 'https://schema.org',
		'@type': 'Course',
		'@id': location.href,
		name: courseName,
		description: courseDescription,
		provider: organization
	});
} else {
	structuredDataEl.textContent = JSON.stringify(organization);
}

document.head.appendChild(structuredDataEl);
